import React from 'react';
import Servidor from 'util/Servidor'
import Storage from 'util/store/Storage';
import { esDominioDatagas } from 'util/utils';

const Footer = () => {
  const { usuario } = Storage.getState()
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright {esDominioDatagas(usuario.dominio) ? 'Datapos' : 'Devices & Tecnology S.A.S'} &copy;  {1900 + new Date().getYear()}{" "}</span>
      <span className="d-inline-block">Codigo de version: {Servidor.versionCode}</span>
    </footer>
  );
}
  ;

export default Footer;
