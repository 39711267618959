import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
const AuditoriaEds = lazy(async () => import('../Configuracion/AuditoriaEds'))
const BitacoraTurno = lazy(async () => import('./Bitacora/BitacoraTurno'))
const PlanillaTurno = lazy(async () => import('./PlanillaTurno'))
/*import Proveedores from './Proveedores/'
import Clientes from './Clientes'*/

const Eds = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/auditoriaeds`} component={AuditoriaEds} />
          <Route path={`${match.url}/bitacorajornada`} component={BitacoraTurno} />
          <Route path={`${match.url}/planillaturnos`} component={PlanillaTurno} />
          {/*<Route path={`${match.url}/proveedores`} component={Proveedores} />
                <Route path={`${match.url}/clientes`} component={Clientes} />*/}
        </Suspense>
      </Switch>
    </div>
  )
}

export default Eds
