import React from "react";
import Highcharts from 'highcharts'
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import moment from "moment";
import Storage from "util/store/Storage";
import Axios from "axios";
import server from "util/Server";
window.Highcharts = Highcharts;
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
class TortasView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            torta_mas_ventas: [],
            checkTortas: 0,
            topSeriesTorta: [],
            isShow: true
        };

    }


    componentWillMount() {
        console.log("entro aca en tortaaaaaa")
        const { data, show } = this.props
        this.setState({
            torta_mas_ventas: data,
            isShow: show
        })

    }

    obteneData = () => {
        const { negocio } = Storage.getState();
        const headers = {
            Authorization: server.obtenerToken(),
            versionapp: server.versionApp,
            versioncode: server.versionCode,
            fecha: moment().format(),
            aplicacion: server.aplicacion,
            identificadordispositivo: server.aplicacion,
        };
        const data = {
            fecha_inicial: moment(new Date())
                .subtract("day", 6)
                .format("YYYY-MM-DD 00:00:00"),
            fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
            identificadorEstacion: this.validarUsuario(),
            identificadorNegocio:negocio
        };

        Axios.post(
            server.host +
            server.puertoMovimiento +
            "/api/reportes/dashboard/torta_mas_ventas",
            data,
            { headers: headers }
        )
            .then((res) => {
                res.data.data.reporteAnual.forEach((reporte) => {
                    reporte.data.forEach((anual) => {
                        if (anual.y !== 0) {
                            this.setState({
                                torta_mas_ventas: res.data.data,
                                topTortasSeries: res.data.data.reporteDiario,
                                torta_mas_ventas_show: true,
                            });
                        }
                    });
                });
                this.setState({
                    torta_mas_ventas: res.data.data,
                    topTortasSeries: res.data.data.reporteDiario,
                });
                if (this.state.primeraCarga) {
                    this.state.mostrarGraficas.push(0);
                }
                if (
                    this.state.mostrarGraficas.length === 7 &&
                    this.state.loaderGraficas
                ) {
                    this.crearGraficas();
                }
            })
            .catch((error) => console.log(error));
    }




    async componentDidMount() {
        //this.crearGraficas()
        //await this.obteneData()
        await this.setVentasTorta(0, this.state.torta_mas_ventas)
    }

    setVentasTorta = (index, data) => {
        let topTortasSeries = [];
        switch (index) {
            case 0:
                topTortasSeries = data.reporteDiario;
                break;
            case 1:
                topTortasSeries = data.reporteSemanal;
                this.setState({
                    isShow: true
                })
                break;
            case 2:
                topTortasSeries = data.reporteMensual;
                this.setState({
                    isShow: true
                })
                break;
            case 3:
                topTortasSeries = data.reporteAnual;
                this.setState({
                    isShow: true
                })
                break;
            default:
                topTortasSeries = data.reporteDiario;
                break;
        }
        this.setState({
            topSeriesTorta: topTortasSeries,
            checkTortas: index
        });
        this.validarData(topTortasSeries)
        this.renderizarGrafica(topTortasSeries)
    };

    validarData = (data) => {
        let vacio = true
        if (Array.isArray(data)) {
            if (Array.isArray(data[0].data)) {
                data[0].data.forEach((dt) => {
                    if (dt.y > 0) {
                        vacio = false
                    }
                })
            }
        }
        this.setState({
            isShow:!vacio
        })
    }

    renderizarGrafica = (series) => {
        Highcharts.chart({
            chart: {
                renderTo: "torta-dos",
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: "",
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                },
            },
            series: series
        });
    }

    render() {
        const { checkTortas, isShow } = this.state
        return (
            <div className="row">
                <div className="text-center noDatosExternos">
                    <h2 className="text-center bg-primary">PORCENTAJE POR VENTA DE COMBUSTIBLES</h2>
                </div>
                <div className="botones mx-auto text-center">
                    <button
                        className={
                            checkTortas === 0
                                ? "btn btn-sm btn-primary"
                                : "btn btn-sm btn-outline-dark"}
                        onClick={() => this.setVentasTorta(0, this.state.torta_mas_ventas)}
                    >
                        HOY
            </button>
                    <button
                        className={
                            checkTortas === 1
                                ? "btn btn-sm btn-primary"
                                : "btn btn-sm btn-outline-dark"}
                        onClick={() => this.setVentasTorta(1, this.state.torta_mas_ventas)}
                    >
                        ESTA SEMANA
            </button>
                    <button
                        className={
                            checkTortas === 2
                                ? "btn btn-sm btn-primary"
                                : "btn btn-sm btn-outline-dark"}
                        onClick={() => this.setVentasTorta(2, this.state.torta_mas_ventas)}
                    >
                        MENSUAL
            </button>
                    <button
                        className={
                            checkTortas === 3
                                ? "btn btn-sm btn-primary"
                                : "btn btn-sm btn-outline-dark"}
                        onClick={() => this.setVentasTorta(3, this.state.torta_mas_ventas)}
                    >
                        ANUAL
            </button>
                </div>
                <div
                    id="torta-dos"
                    className="col-md-11 mx-auto"
                    style={
                        isShow
                            ? { marginTop: "15px", display: "block" }
                            : { display: "none" }
                    }
                ></div>
                {isShow ? null : <div className="text-center noDatos">
                    {" "}
                    <span>NO HAY DATOS{
                        checkTortas === 0 ? " HOY"
                            : checkTortas === 1 ? " ESTA SEMANA"
                                : checkTortas === 2 ? " ESTE MES"
                                    : " ESTE AÑO"}</span>{" "}
                </div>}
            </div>

        );
    }
}

export default TortasView;
