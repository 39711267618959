//PRINCIPALES
export const DASHBOARD =1
export const EMPRESA =17
export const CONFIGURACION =29
export const FIDELIZACION =30
export const REPORTES =31
export const USUARIOS =32
export const MOVIMIENTOS =121
export const EDS =128
export const MODULOS_POS =133

//MODULOS DASHBOARD
export const INDICADOR_VENTAS_CANASTILLA = 3
export const INDICADOR_VENTAS_FIDELIZADAS = 4
export const INDICADOR_VENTAS = 5
export const INDICADOR_ESTACIONES = 6
export const INDICADOR_USUARIOS = 7
export const VENTAS_POR_TURNOS = 8
export const PROMOTORES_VENTAS_TOP = 9
export const VENTA_POR_DIAS_DEL_MES = 10
export const TOP_10_ESTACIONES = 11
export const TOP_10_PRODUCTOS_MAS_VENDIDOS = 12
export const VENTA_DE_PRODUCTOS_POR_MESES = 13
export const VENTA_COMBUSTIBLE_MES_ACTUAL = 14
export const VENTA_COMBUSTIBLE_POR_ISLA = 15
export const GRAFICA_DE_TORTA = 16
export const GRAFICA_TOP_DIAS_MAS_VENTAS = 21
export const VENTAS_POR_PROMOTOR_ESTACION = 26
export const COMBINADO = 27
export const INDICADOR_PRODUCTOS = 28
export const INDICADOR_VEHICULOS = 119
export const INDICADOR_DE_COMPRAS = 156
export const INDICADOR_TALLERES = 157
export const INDICADOR_FINANCIERAS = 158 // listo
export const INDICADOR_VERIFICADORA = 159
export const INDICADOR_DE_ESTACIONES = 160
export const INDICADOR_DE_CONVERSIONES = 161
export const INDICADOR_DE_CREDITOS = 162 // listo
export const INDICADOR_TIPO_CLIENTES = 163
export const CONVERSIONES_ESTA_SEMANA = 164
export const INDICADOR_ACUERDOS_EDS = 169
export const INDICADOR_RECAUDOS = 170
export const CREDITOS_ESTA_SEMANA = 171

//MODULOS DE EMPRESA
export const SOPORTE = 18
export const MONITOREO = 19
export const MIS_NEGOCIOS = 20
export const ESTRUCTURA = 22
export const GESTION_Y_CONFIGURACION_EDS = 23
export const VEHICULOS = 24;
export const FLOTAS = 25
export const TALERES = 65
export const MIS_EDS = 174

//MODULOS DE CONGIFURACION

export const GESTION_PERFILES = 33 
export const ACUERDOS = 34 //listo
export const UNIDADES = 35 
export const FACTURAS = 36 
export const PROVEEDORES = 37 
export const BODEGA = 38 
export const TANQUES_Y_CONFIGURACION = 40 //listo
export const CATEGORIAS = 41
export const MEDIOS_DE_PAGO = 42
export const IMPUESTOS = 43
export const PRODUCTOS = 44 //listo
export const RESOLUCIONES = 45 //listo
export const TURNOS = 46 //listo
//LOS QUE FALTAN
export const EMPLEADOS = 116 //listo
export const ENTRADA = 124//listo
export const SALIDA = 126//listo
export const ORDEN_DE_COMPRA = 123 //listo
export const CLIENTES = 117 //listo
export const TRANSLADOS = 125
export const ANULACION_DE_VENTAS = 127
export const ACUERDOS_EDS = 152//listo
export const ACUERDOS_CLIENTES= 134
export const VISTA_CONVERSIONES_GNV = 153
export const GESTION_DESCUENTOS = 173

export const MEDIOS_IDENTIFICACION = 50 

