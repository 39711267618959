import React, { useState } from 'react'
import jsPDF from 'jspdf'
import { applyPlugin } from 'jspdf-autotable'
import 'primereact/resources/primereact.min.css'
import 'primereact/components/datatable/DataTable.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import './prime.css'
import { DataTable as PRDatatable, DataTableProps } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import excelIcon from '../assets/images/excel.png'
import PdfIcon from '../assets/images/pdf.png'
import Storage from 'util/store/Storage'
import { esDominioFuelly, esNumero, snakeCaseToSeparatedUppercase } from 'util/utils'
applyPlugin(jsPDF)

export interface columnsDatatable {
  field: string
  header: string
  number?: boolean | number
  frozen?: boolean
}
export interface columnsPDF {
  header: string
  dataKey: string
}
export interface PropsDatatable extends DataTableProps {
  data: any[]
  columns: columnsDatatable[]
  noExport?: boolean
  fontSize?: number
  nombreExcel?: string
  nombrePDf?: string
  select?: any
}
const DataTableFilterDemo: React.FC<PropsDatatable> = props => {
  const { data, columns, noExport = false, rows = 10, fontSize = 9, nombreExcel = 'Excel', nombrePDf = 'PDF' } = props
  const [globalFilter, setGlobalFilter] = useState('')

  // funcion para catualizar la vista donde se envuentra el componente
  const actualizar = () => {
    Storage.dispatch({
      type: 'ACTUALIZACION_DATOS',
      actualizarvista: true
    })
  }

  const textoCentrado = (doc: any, text: string, alto: number): any => {
    const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2
    return doc.text(textOffset, alto, text)
  }

  // funcion para exportar pdf
  const exportPdf = (): void => {
    const doc: any = new jsPDF('l', 'pt', 'legal')
    doc.setFontSize(14)
    textoCentrado(doc, nombrePDf, 20)
    //doc.text(nombrePDf, 20, 20)
    doc.setFont('helvetica', 'italic')
    doc.setFontSize(10)
    doc.autoTable({
      startY: 50,
      body: data,
      columns: columns.map(columna => ({
        header: columna.header,
        dataKey: columna.field
      })),
      styles: { fontSize: fontSize },
      headStyles: { fillColor: [186, 0, 0] }
    })
    doc.save(nombrePDf + '.pdf')
  }

  const formatearValue = (value: any): void => {
    if (typeof value === 'string') {
      value = value
        .replace(/\$/g, '')
        .replace(/\./g, '')
        .replace(/,/g, '.')
      value = !esNumero(value) ? value : value.includes('/') || value.includes(':') ? value : parseFloat(value)
    }
    return value
  }

  const formatearExcel = (datos: any) => {
    if (datos) {
      return datos.map((data: any) => {
        return Object.entries(data).reduce((obj: any, [key, value]) => {
          obj[key] = formatearValue(value)
          return obj
        }, {})
      })
    } else {
      return []
    }
  }

  const exportExcel = () => {
    const { usuario } = Storage.getState()
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(
        !esDominioFuelly(usuario.dominio) ? formatearExcel(snakeCaseToSeparatedUppercase(data)) : data
      )
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      })
      saveAsExcelFile(excelBuffer, nombreExcel)
    })
  }

  const saveAsExcelFile = (buffer: Buffer, fileName: string): void => {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      let EXCEL_EXTENSION = '.xlsx'
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      })
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
    })
  }

  const Header = () => {
    return (
      <div style={{ textAlign: 'left' }}>
        <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
          <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
          <InputText
            type="search"
            onInput={(e: any) => setGlobalFilter(e.target.value)}
            placeholder="Buscar..."
            width="20%"
          />
          {noExport
            ? null
            : [
                <button
                  key="1"
                  className="btn btn-primary"
                  id="exportExcel"
                  onClick={exportExcel}
                  style={{ marginLeft: '15px' }}
                >
                  <img src={excelIcon} alt="excelIcon" />
                </button>,
                <button
                  key="2"
                  className="btn btn-primary"
                  id="exportPdf"
                  onClick={exportPdf}
                  style={{ marginLeft: '15px' }}
                >
                  <img src={PdfIcon} alt="PdfIcon" />
                </button>
              ]}
          <Button icon="pi pi-refresh" onClick={() => actualizar()} style={{ float: 'right' }} />
        </div>
      </div>
    )
  }

  const agregarSelect = (dato: any) => {
    Storage.dispatch({
      type: 'DATOS_SELECCIONADOS',
      seleccionados: dato
    })
  }

  return (
    <div className="mx-auto" style={{ overflow: 'auto' }}>
      <div className="content-section implementation">
        <PRDatatable
          id="htmlTableId"
          className="mx-auto"
          value={data}
          paginator={true}
          rows={rows}
          header={Header()}
          globalFilter={globalFilter}
          sortMode="multiple"
          emptyMessage="No se encontro"
          rowsPerPageOptions={[5, 10, 20, 50]}
          removableSort={true}
          responsive={true}
          autoLayout
          selection={Storage.getState().seleccionados}
          onSelectionChange={e => agregarSelect(e.value)}
          /* rowReorderIcon="pi pi-bars"
          rowReorder={true} */
        >
          {props.select && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
          {columns != null
            ? columns.map((col, i) => {
                return (
                  <Column
                    key={i}
                    field={col.field}
                    header={col.header}
                    style={col.number ? { 'text-align': 'right' } : { width: '300px' }}
                    sortable={true}
                    filterPlaceholder="Buscar..."
                    frozen={col.frozen}
                  />
                )
              })
            : null}
        </PRDatatable>
      </div>
    </div>
  )
}

export default DataTableFilterDemo
