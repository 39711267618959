import React from 'react';
import {setearMiles} from "../../../../../util/funciones"
const Indicadores = ({icon,subtitulo, titulo, datos, datoArriba,datoAbajo, descripcionAbajo, color, tipo,imgDefault}) => {
    
    return ( <div className="col-md-3 mx-auto">
    <div
      className="media align-items-center flex-nowrap jr-card-full jr-dot-arrow-hover shadow mx-auto"
      style={{ border: "1px solid #cccccc", margin: "5px" }}
    >
      <div className="px-3 jr-build-box-lay">
        <img
          alt="..."
          src={icon}
          onError={(e) => { e.target.onerror = null; e.target.src = imgDefault }}
          className=""
          style={{maxHeight:'72px'}}
        />
      </div>
      <div className="media-body py-3 pr-4 jr-build-box-lay-content">
        <span
          className="badge text-white"
          style={{ backgroundColor: color, padding: "10px !important" }}
        >
          {datoArriba} {subtitulo}
        </span>
        <h4 className="text-truncate mb-0">{titulo}</h4>

        <p className="mb-0 text-grey jr-fs-sm">
          {descripcionAbajo}{" "}
          <b style={{ color: "red" }}>
            {!tipo && "$"} {datoAbajo === null ? 0 : setearMiles(datoAbajo)}
          </b>
        </p>
        {/*<div
          className="jr-dot-arrow"
          onClick={() => {
            alert("vamos para ventas");
          }}
        >
          <div className="bg-primary jr-hover-arrow">
            <i className="zmdi zmdi-long-arrow-right text-white" />
          </div>
          <div className="jr-dot">
            <i className="zmdi zmdi-more-vert text-primary" />
          </div>
        </div>*/}
      </div>
    </div>
  </div> );
}

export default Indicadores;