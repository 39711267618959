import React from "react";
import Highcharts from 'highcharts'
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import {setearMiles} from "util/funciones"
window.Highcharts = Highcharts;
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
class TortasView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkTortas: 0,
            seriesMeses: [],
            categoriesMeses: [],
            isShow: true
        };
    }


    componentWillMount() {
        const { categories,series } = this.props
        this.setState({
            seriesMeses: series,
            categoriesMeses: categories
        })
    }

    async componentDidMount() {
        //this.crearGraficas()
        //await this.obteneData()
        await this.renderizarGrafica(this.state.categoriesMeses,this.state.seriesMeses)
    }


    validarData = (data) => {
        let vacio = true
        if (Array.isArray(data)) {
            if (Array.isArray(data[0].data)) {
                data[0].data.forEach((dt) => {
                    if (dt.y > 0) {
                        vacio = false
                    }
                })
            }
        }
        this.setState({
            isShow:!vacio
        })
    }

    renderizarGrafica = (categories,series) => {
        Highcharts.chart("ventas-x-producto-surtidor-externo", {
            chart: {
              type: "column",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: categories,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: "VENTA DE COMBUSTIBLE POR MESES",
              },
            },tooltip: {
              formatter: function () {
                return '<b>' + this.point.category + '</b>:  $' + setearMiles(this.point.y);
              }
            },
            /*tooltip: {
              headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b> $ {point.y}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
            },*/
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: series,
          });
    }

    render() {
        const { isShow } = this.state
        return (
            <div className="row">
                <div className="text-center noDatosExternos">
                    <h2 className="text-center bg-primary">VENTA DE COMBUSTIBLE POR MESES</h2>
                </div>
                <div
                    id="ventas-x-producto-surtidor-externo"
                    className="col-md-11 mx-auto"
                    style={
                        isShow
                            ? { marginTop: "15px", display: "block" }
                            : { display: "none" }
                    }
                ></div>
                {isShow ? null : <div className="text-center noDatos">
                    <span>NO HAY DATOS</span>{" "}
                </div>}
            </div>

        );
    }
}

export default TortasView;
